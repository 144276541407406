@import 'globals.less';

main section {
	#basta-webbhotell img {
		width: 100%;
		margin: 15px 0 25px;
	}

	&:first-child {
		background: linear-gradient(to right, #3979cd, #21559a);

		@media (max-width: 991px) {
			& {
				background: var(--blue);
			}
		}
	}

	&:nth-child(2) {
		padding-top: 135px;
		background-color: var(--backgroundgray);

		h2 {
			text-align: center;
			font-size: 40px;
			color: var(--blue);
		}
	}

	&:nth-child(4) {
		padding: 0;
	}
}

.intro {
	.sub-heading {
		display: block !important;
		color: var(--lightorange) !important;
		font-size: 34px !important;
			margin-top: 0 !important;
		
		@media (max-width: @media_m) {
			text-align: center !important;
		}
	}

	h2, h3 {
		margin: 0 0 50px;
		text-align: center;
		font-size: 50px;
		color: var(--lightorange);

		@media (max-width: @media_m) {
			& {
				margin: 50px 0;
			}
		}
	}

	.card-small-webhotel {
		margin: 0 auto;

		@media (min-width: 992px) {
			& {
				width: 100%;
			}
		}

		.left img {
			height: inherit;
		}
	}
}

.info h4,
.info h5 {
	margin: 0 0 10px;
	font-size: 22px;
}

#youtube {
	margin-bottom: -50px;
	padding: 50px 0 100px 0;
	background-color: #124673;

	@media (max-width: 768px) {
		margin-bottom: 0;
		padding: 50px 0;
	}

	.row {
		margin-top: 0;
		margin-bottom: 0;
		padding: 0 30px;

		iframe {
			height: 500px;
			width: 100%;
			max-width: 880px;
			margin: 0 auto;

			@media (max-width: 768px) {
				height: 300px;
			}
		}
	}
}

/*
#tribusoft-ad {
	position: relative;
	height: 185px;
	width: 100%;
	max-width: 1140px;
	margin: 50px auto -135px;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);

	> div {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		padding: 15px 20px;
	}

	> div > div {
		display: flex;
		align-items: center;
	}

	.col-1 {
		width: 25%;
		padding-right: 20px;

		img {
			max-height: 100%;
			max-width: 100%;
			object-fit: contain;
			border-radius: 5px;
			overflow: hidden;
		}
	}

	.col-2 {
		width: 20%;
		padding: 0 10px 0 30px;
		color: #333;
		border-left: 1px solid lightgray;
		border-right: 1px solid lightgray;
	}

	.col-3 {
		width: 40%;
		padding: 0 20px 0 10px;
		color: #333;
		border-right: 1px solid lightgray;
	}

	.col-4 {
		width: 15%;
		padding-left: 15px;

		a {
			float: left;
			width: 100%;
			padding: 15px 0;
			font-size: 22px;
			text-align: center;
			text-decoration: none;
			color: white;
			background-color: var(--orange);
			border-radius: 5px;
			font-weight: bold;
			box-shadow: 0 5px 0 0 #866418;
			text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
		}
	}
}
*/

#tribusoft-ad {
	position: relative;
	height: 210px;
	width: 100%;
	max-width: 1140px;
	margin: 50px auto -135px;
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
	overflow: hidden;

	@media (max-width: 1200px) {
		height: auto;
		max-width: 962px;
	}

	> div {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
		padding: 15px 20px;
	}

	> div > div {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.col-1 {
		justify-content: center;
		width: 300px;
		margin: -15px -20px;
		padding: 15px 20px;
		background: #2e5b79;

		@media (max-width: 1200px) {
			width: calc(100% + 40px);
		}

		img {
			width: 100%;
			height: 100%;
			max-width: 60px;
			max-height: 60px;
		}

		.title {
			font-weight: bold;
			text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
			font-size: 32px;
			margin-left: 10px;
		}

		.link-container {
			width: 100%;
			max-width: 250px;

			@media (max-width: 700px) {
				max-width: 100%;
				margin: 20px 0;
			}
		}

		a {
			display: block;
			padding: 10px 15px;
			font-size: 20px;
			color: white;
			background-color: var(--orange);
			border-radius: 5px;
			text-decoration: none;
			font-weight: bold;
			text-align: center;
			box-shadow: 0 5px 0 0 #866418;
			text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

			@media (max-width: 1200px) {
				margin-left: 40px;
			}

			@media (max-width: 700px) {
				margin: 0 auto;
				max-width: 250px;
			}
		}
	}

	.col-2,
	.col-3 {
		@media (max-width: 1200px) {
			margin-top: 40px;
		}

		@media (max-width: 700px) {
			width: 100% !important;
		}

		li {
			display: block;
			color: #333;
		}
	}

	.col-2 {
		width: 370px;
		padding-left: 40px;
		padding-right: 20px;

		@media (max-width: 1200px) {
			width: 50%;
		}

		@media (max-width: 700px) {
			padding-left: 0;
			padding-right: 0;
		}

		ul {
			padding-left: 2rem;

			li {
				position: relative;
				height: 50px;
				padding-left: 10px;
				margin: 13px 0 0px 20px;
				font-size: 18px;
				font-weight: bold;

				i {
					font-size: 1.5rem;
					line-height: 3rem;
					width: 3rem;
					height: 3rem;
					position: absolute;
					left: -3.5rem;
					top: -0.6rem;
					margin: auto;
					background-color: #2e5b79;
					color: white;
					text-align: center;
					text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
					border-radius: 50%;
				}
			}
		}
	}

	.col-3 {
		height: 100%;
		width: calc(100% - 300px - 340px);
		border-left: 1px solid lightgray;

		@media (max-width: 1200px) {
			width: 50%;
			border: none;
		}

		@media (max-width: 700px) {
			margin-top: 10px;
		}

		ul {
			padding-left: 30px;

			@media (max-width: 700px) {
				padding-left: 0;
				padding-right: 0;
			}

			li {
				position: relative;
				padding-top: 0;
				margin: 15px 0 24px 20px;

				i {
					position: absolute;
					display: block;
					left: -1.2rem;
					top: 0.2rem;
					font-size: 1.2rem;
				}
			}
		}
	}
}
